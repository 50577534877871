 import Dabion from "../images/Dabion.png"
 
 export default function About(){

    return(
        <>  
            
            <section class="sec" id="team">
                    <div class="container">
                        <div class="card" id="redteam">
                            <div class="content">
                                <h2>Dabion</h2>
                                <p> 
                                1993 Mazda RX7</p>
                            </div>
                            <img alt = "no imgage" src={Dabion} width="300" height="300"></img>
                        </div>
                    </div>
                </section>
        </>
    )
        


    
}