import React from "react"
import Signup from "./Signup"
import { AuthProvider } from "../Context/AuthContext"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Dashboard from "./Dashboard"
import Profile from "./Profile"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import UpdateProfile from "./UpdateProfile"
import ForgotPassword from "./ForgotPassword"
import Store from "./Store"
import Home from "./Home"
import NFT from "./NFT"
import About from "./About"
import "../Style.css"

let username = ""


if ( !["/About", "/NFT", "/Home", "/Store", "/login", "/signup", "/ForgotPassword", "/UpdateProfile"].includes(window.location.pathname)){
  username = window.location.pathname.substring(1)
}


function App() {

  return (
        <Router>
          <AuthProvider>
            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path="/" element={<Dashboard/>} />
                <Route path="/UpdateProfile" element={<UpdateProfile/>} />
              </Route>
              <Route path={"/"+username} element={<Profile/>} />
              <Route path={"/Signup"} element={<Signup/>} />
              <Route path={"/Login"} element={<Login/>} />
              <Route path={"/ForgotPassword"} element={<ForgotPassword/>} />
              <Route path={"/Store"} element={<Store/>} />
              <Route path={"/Home"} element={<Home/>} />
              <Route path={"/About"} element={<About/>} />
              <Route path={"/NFT"} element={<NFT/>} />
            </Routes>
          </AuthProvider>
        </Router>


  )
}

export default App