export default function NFT(){

    return(
        <>
                <section class="sec" id="team">
                    <div class="container">
                        <div class="card" id="yellowteam">
                            <div class="content">
                                <h2>NFT</h2>
                                <p> 
                                1993 Mazda RX7</p>
                            </div>
                            
                        </div>
                    </div>
                </section>


                <section>
                    <h1>Hello World</h1>
                </section>
            
        </>
    )
        


    
}