export default function Store(){

    return(
        <section class="sec" id="team">
                <div class="container">
                    <div class="card" id="blueteam">
                        <div class="content">
                            <h2>Store</h2>
                            <p> 
                            1993 Mazda RX7</p>
                        </div>
                        </div>
                </div>
            </section>

    )
        


    
}