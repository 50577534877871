import BrandNameWhite from "../images/BrandNameWhite.png"

export default function Home(){

    return(
        <>
            <section class="banner" id="home">
            <div id="set-height"></div>
                    <div class="header">
                        <h1><img src={BrandNameWhite} alt="EXZA" width="300" height="75"></img></h1>
                    </div>
                    
            <script src="sticky.js"></script>
            <script>
                
                
            </script>
            
        
            </section>
            
            
                
            
        </>
    )
        


    
}